import { Icon } from '@iconify/react'
import ScrollContainer from 'react-indiana-drag-scroll'

type plan = {
  name: string
  logo: string
  carrier: string
  dataSize?: number
  isUnlimited: boolean
  notes?: string
  monthlyPrice: number
  monthlySpecialPrice: number
  isMonthlyPriceMore?: boolean
  freeCallMin: number
  wifi: string
  domesticCallPrice: number
  smsPrice: number
  mmsPrice: number
}

export const PlansMenu = (props: { data: plan[] }) => {
  return (
    <div className='flex flex-row gap-x-2'>
      <div className='w-1/4 md:w-1/4 flex flex-col'>
        <div className='text-price-theme-dark font-bold text-sm text-right'>
          <div className='h-[72px]' />
          <div className='h-[112px] grid items-center'>
            高速データ
            <br className='md:hidden' />
            容量
          </div>
          <div className='h-[79px] grid items-center'>
            月額基本
            <br className='md:hidden' />
            料金
          </div>
          <div className='h-[57px] grid items-center'>無料通話</div>
          <div className='h-[48px] grid items-center'>
            キャリア
            <br className='md:hidden' />
            提供 WiFi
          </div>
          <div className='h-[72px] grid items-center'>
            ベリー間
            <br className='md:hidden' />
            通話
          </div>
          <div className='h-[68px] grid items-center' />
          <div className='h-[53px] grid items-center'>
            国内
            <br className='md:hidden' />
            通話料金
          </div>
          <div className='h-[53px] grid items-center'>
            SMS
            <br className='md:hidden' />
            送信料金
          </div>
          <div className='h-[53px] grid items-center'>
            MMS
            <br className='md:hidden' />
            送信料金
          </div>
        </div>
      </div>
      <ScrollContainer
        className='scroll-container w-3/4 md:w-3/4'
        hideScrollbars={false}
      >
        <div className='flex justify-between gap-x-3'>
          {props.data.map((plan, i) => (
            <div key={plan.name} className='flex flex-col grow min-w-64'>
              <div className='overflow-hidden flex flex-col rounded-xl text-center'>
                <div className='bg-price-theme-dark text-white text-lg px-4 py-2 font-semibold flex items-center justify-center gap-x-4'>
                  <img src={plan.logo} alt={plan.carrier} className='h-9' />
                  <div
                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{ __html: plan.name }}
                    className='whitespace-nowrap'
                  />
                </div>
                <div className='bg-price-theme-light text-xs px-4 py-2 space-y-2 h-28 grid'>
                  <div className='grid grid-cols-[1fr_auto_1fr] justify-items-center items-center gap-x-2'>
                    <Icon
                      icon='fluent:cellular-5g-20-filled'
                      className='text-black h-9 w-9'
                    />
                    <div className='font-bold text-md'>
                      <span
                        className={`${
                          plan.isUnlimited
                            ? 'text-4xl md:text-5xl lg:text-6xl'
                            : 'text-5xl'
                        }`}
                      >
                        {plan.isUnlimited ? '無制限' : plan.dataSize}
                      </span>
                      {plan.dataSize && <span className='text-xl'>GB</span>}
                    </div>
                  </div>
                  {plan.notes && <div className='font-bold'>{plan.notes}</div>}
                </div>
                <div className='font-bold bg-price-theme-base px-4 py-2 flex items-center gap-x-2 justify-center'>
                  <div>
                    <div className='relative'>
                      <span className='text-xl'>
                        {plan.monthlyPrice.toLocaleString()}
                      </span>
                      <span className='text-[0.5rem]'>THB</span>
                      <hr className='absolute top-0 bottom-0 m-auto w-full border-black border-dotted' />
                    </div>
                    <div className='flex'>
                      <div className='bg-[#fff101] w-fit py-1 pl-2 text-left text-[0.6rem] leading-tight whitespace-nowrap'>
                        6ヵ月間
                        <br />
                        割引適用
                      </div>
                      <div className='w-0 h-0 border-transparent inline-block border-l-[#fff101] border-[1em]' />
                    </div>
                  </div>
                  <div>
                    <span className='text-5xl'>
                      {plan.monthlySpecialPrice.toLocaleString()}
                    </span>
                    <span className=''>THB</span>
                  </div>
                </div>
                <div className='font-bold bg-price-theme-light px-4 py-2'>
                  <span className='text-4xl'>
                    {plan.freeCallMin.toLocaleString()}
                  </span>
                  <span className=''>min</span>
                </div>
                <div className='bg-price-theme-base font-bold px-4 py-2'>
                  <span className='text-2xl'>{plan.wifi} 無制限</span>
                </div>
                <div className='font-bold bg-price-theme-light px-4 py-2'>
                  <div className='text-xl'>ベリー {plan.carrier} 間通話</div>
                  <div className='text-lg'>タイ国内 24 時間無料</div>
                </div>
                <div className='font-bold text-white bg-price-theme-dark px-4 py-5'>
                  <span className='text-xl'>プラン詳細</span>
                </div>
                <div className='font-bold bg-price-theme-base px-4 py-2'>
                  <span className='text-3xl'>{plan.domesticCallPrice}</span>
                  <span className='text-lg'>THB</span>
                </div>
                <div className='font-bold bg-price-theme-light px-4 py-2'>
                  <span className='text-3xl'>{plan.smsPrice}</span>
                  <span className='text-lg'>THB</span>
                </div>
                <div className='font-bold bg-price-theme-base px-4 py-2'>
                  <span className='text-3xl'>{plan.mmsPrice}</span>
                  <span className='text-lg'>THB</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </ScrollContainer>
    </div>
  )
}
